import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import UniversityExplorerComponent from '../universityExplorer'
import WayupHeader from '../components/WayupHeader'
import Sidebar from '../universityExplorer/Sidebar'
import '../pages/marketplace/tools/university-explorer/index.sass'
import mixpanel from 'mixpanel-browser'
import './index.sass'

// manage filters here probably?
// do we want to do filters on the frontend?
// yes, that way filtering is always fast right?
// hmm. lets figure that out after we just at least get fujio set up with
// building the frontend.

export default class UniversityExplorerPage extends React.Component {
  constructor(props) {
    super()

    const {
      data: {
        allSchoolMetadata: { edges },
      },
    } = props

    const schools = _.map(edges, 'node')

    console.log('school length')
    console.log(schools.length)
    this.state = {
      schoolsByUnitId: schools.reduce((acc, school) => ({ ...acc, [school.unitid]: school }), {}),
      filters: [],
      filterSubjects: [],
    }
  }

  // componentDidMount() {
  //   mixpanel.track('wayup:loaded')
  // }

  attachSchoolInfoToUnitIDs = rows => {
    const { schoolsByUnitId } = this.state
    return rows.map(row => ({
      ...row,
      ...schoolsByUnitId[row.unitid],
    }))
  }

  resetFilters = () => {
    this.setState({ filters: [] })
  }

  toggleFilter = async (filter, title, added) => {
    ;(await this.state.filters.find(selected => selected._id === filter._id))
      ? this.setState({
          filters: this.state.filters.filter(selected => selected._id !== filter._id),
        })
      : this.setState({ filters: this.state.filters.concat([filter]) })

    // Check if the filter was added or removed
    if (added) {
      mixpanel.track('ri:ue-filters', {
        wayup: true,

        // All currently active filter labels
        allFilters: this.state.filters.map(filter => {
          return filter.label
        }),

        // Just activated filter
        activatedFilter: filter.label,

        // Just activated filter type
        activatedFilterType: title,
      })
    }
  }

  setFilter = filter =>
    this.setState({
      filters: this.state.filters.filter(selected => selected._id !== filter._id).concat(filter),
    })

  removeFilter = filter =>
    this.setState({ filters: this.state.filters.filter(selected => selected._id !== filter._id) })

  render() {
    const { filters } = this.state
    return (
      <>
        <div className="universityExplorer wayup">
          <WayupHeader />

          <div className="columns is-gapless">
            <div className="column is-one-quarter">
              <Sidebar
                resetFilters={this.resetFilters}
                selectedFilters={this.state.filters}
                toggleFilter={this.toggleFilter}
                setFilter={this.setFilter}
                removeFilter={this.removeFilter}
                wayup={true}
              />
            </div>
            <div className="column is-clipped">
              <UniversityExplorerComponent
                wayup={true}
                // data={data}
                attachSchoolInfoToUnitIDs={this.attachSchoolInfoToUnitIDs}
                // groups={groups}
                // selectedCategory={selectedGroup}
                cipCodes={['15_1201', '15_1202']}
                filters={filters.map(filter => filter.filter)}
              />
            </div>
          </div>
          <div className="wayup-footer">
            <div className="wayup-footer-container">
              <h5>University Explorer™ uses the following data sources: </h5>
              <p>
                (1){' '}
                <strong>
                  IPEDS Access Database{' '}
                  <a
                    target="_blank"
                    href="https://nces.ed.gov/ipeds/use-the-data/download-access-database"
                    rel="noopener noreferrer"
                  >
                    (2019-2020 Provisional Data Release)
                  </a>
                </strong>
                . The Integrated Postsecondary Education Data System (IPEDS) is a system of
                interrelated surveys conducted annually by the U.S. Department of Education’s
                National Center for Education Statistics (NCES). IPEDS annually gathers information
                from about 6,400 colleges, universities, and technical and vocational institutions
                that participate in the federal student aid programs.
              </p>
              <p>
                (2){' '}
                <strong>
                  <em>The College Pulse Data Vault™</em>
                </strong>
                . The Data Vault is a proprietary database of over 100 million survey responses
                collected from the American College Student Panel–an online community of over
                450,000 students attending over 1500 universities from all 50 states the US.
              </p>
              <p>
                For further information regarding data sources, please email{' '}
                <a href="mailto:methodology@collegepulse.com">methodology@collegepulse.com</a> or
                visit{' '}
                <a href="https://www.collegepulse.com/methodology">
                  https://www.collegepulse.com/methodology
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export const UEPageQuery = graphql`
  query WayUpQuery {
    allSchoolMetadata {
      edges {
        node {
          unitid
          usBased
          institutionName
          institutionNameAlias
          usNewsRanking
          top100Liberal
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`
