import React from 'react'

// import wayuplogo from '../wayup_pages/wayup.png'
// import cp from '../wayup_pages/White_Logo.png'

const WayupHeader = () => {
  return (
    <div className="level wayup-background">
      <div className="level-left">
        <img
          style={{ height: '4rem', width: 'auto' }}
          src="https://wayup.collegepulse.com/img/wayup.png"
        />
        <div className="powered-by">
          <h5>Powered by:</h5>
          <img src="https://wayup.collegepulse.com/img/White_Logo.png" />
        </div>
      </div>
    </div>
  )
}

export default WayupHeader
